import { useRef } from 'react';
import Body from './components/Body';
import Header from './components/Header';

function App() {
  
  const introRef = useRef<HTMLDivElement>(null);
  const introCallback = () => {
    introRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const aboutRef = useRef<HTMLDivElement>(null);
  const aboutCallback = () => {
    aboutRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const workHistoryRef = useRef<HTMLDivElement>(null);
  const workHistoryCallback = () => {
    workHistoryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const contactMeRef = useRef<HTMLDivElement>(null);
  const contactMeCallback = () => {
    contactMeRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="container">
      <Header introCallback={introCallback}
              aboutMeCallback={aboutCallback}
              workHistoryCallBack={workHistoryCallback}
              contactMeCallback={contactMeCallback}/>
      <Body introRef={introRef}
            aboutMeRef={aboutRef}
            workHistoryRef={workHistoryRef}
            contactMeRef={contactMeRef}
            />
   </div>
  );
}

export default App;
