import { RefObject } from "react";
import { ReactTyped } from "react-typed";

function AboutMe({passedRef}: {passedRef: RefObject<HTMLDivElement>}) {
    return (
        <div id={"intro"} ref={passedRef}  className="container section">
            <div className="row">
                <div className="col-sm-8">
                    <ReactTyped className="header" strings={["About Me"]} cursorChar="_"/>
                    <p className="indented">
                        Throughout my life, I have worn many hats. Every experience, whether good or bad, has shaped me into who I am today. 
                        As I navigate the professional world and the ebb and flow of work-life balance, I continuously strive for improvement. 
                        Instead of a lengthy explanation of my professional persona, let me share who I am as a person.
                    </p>
                    <p className="indented">
                        I am a veteran.
                        Punctuality and discipline were instilled in me during my service. I can function on little to no sleep, fueled by coffee 
                        and Rip-its. My time in the military taught me the importance of leadership and teamwork. I can lead and provide guidance, 
                        follow directions, and delegate tasks effectively. These experiences have made me resilient and adaptable, qualities I bring 
                        to every aspect of my life.
                    </p>
                    <p className="indented">
                        I am a father/husband.
                        I strive to be a positive role model in my daughters' lives. I am active and present, always there to support and 
                        encourage them. Whether it's helping with homework, cheering from the sidelines at their sporting events, or simply 
                        spending quality time together, I prioritize making our house a place filled with laughter and smiles. My family 
                        inspires me to be the best version of myself, and I am dedicated to doing my best for them.
                    </p>
                    <p className="indented">
                        I am me.
                        I am flexible and a problem solver, constantly seeking new challenges and opportunities to grow. I succeed, but 
                        I also learn from my failures. I wear many hats, and I remain ambitious, always on the lookout for the next hat 
                        to add to my collection. Whether it's taking on a new project at work, learning a new technology, or finding ways 
                        to improve myself personally, I embrace the journey of continuous improvement.
                    </p>
                    <p className="indented">
                        In all these roles, my core values of integrity, dedication, and perseverance remain constant. I am passionate 
                        about what I do, both professionally and personally, and I am always eager to take on new challenges and make a 
                        positive impact.
                    </p>
                </div>
                <div className="col-sm-4 image-container">
                    <img src="/family.jpg" alt="Warring family"/>
                </div>  
            </div>
        </div>
    );
  }
  
  export default AboutMe; 