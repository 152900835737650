import { ReactTyped } from "react-typed";

function Header({introCallback, aboutMeCallback, workHistoryCallBack, contactMeCallback} : {introCallback: VoidFunction,
                                                                                            aboutMeCallback:  VoidFunction,
                                                                                            workHistoryCallBack:  VoidFunction,
                                                                                            contactMeCallback:  VoidFunction}) {
  return (
  <header className="sticky">
    <button className="logo dark-button"
            onClick={() => introCallback()}>                  
                  <ReactTyped strings={["AW"]} cursorChar="_"/>
    </button>
    <button className="button dark-button"
            onClick={() => aboutMeCallback()}>About Me</button>
    <button className="button dark-button"
            onClick={() => workHistoryCallBack()}>Work History</button>
    <button className="button dark-button"
            onClick={() => contactMeCallback()}>Contact Me</button>
    <a className="button dark-button" href="/WarringResume2024.pdf" download>Resume Download</a>
  </header>
  );
}

export default Header; 