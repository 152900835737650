import { RefObject } from "react";
import { ReactTyped } from "react-typed";

function Intro({passedRef}: {passedRef: RefObject<HTMLDivElement>}) {
    const typedStrings = ["I am A Software Engineer", 
                          "I am A",
                          "I am A Scrum Master", 
                          "I am A",
                          "I am A First Generation Graduate", 
                          "I am A", 
                          "I am A Coach", 
                          "I am A", 
                          "I am A Veteran", 
                          "I am A", 
                          "I am A Father", 
                          "I am A", 
                          "I am Antony Warring"];

    return (
    <div id={"intro"} ref={passedRef} className="section intro-container">
        <div className="intro">
            <ReactTyped strings={typedStrings} typeSpeed={50} backDelay={200} backSpeed={50} cursorChar="_"/>
        </div>
    </div>
    );
  }
  
  export default Intro; 