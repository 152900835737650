import AboutMe from "../sections/AboutMe";
import ContactMe from "../sections/ContactMe";
import WorkHistory from "../sections/WorkHistory";
import Intro from "../sections/Intro";
import { RefObject } from "react";

function Body({ introRef, aboutMeRef, workHistoryRef, contactMeRef } : { introRef: RefObject<HTMLDivElement>, 
                                                                         aboutMeRef: RefObject<HTMLDivElement>,
                                                                         workHistoryRef: RefObject<HTMLDivElement>, 
                                                                         contactMeRef: RefObject<HTMLDivElement>}) {
    return (
    <div>
        <Intro passedRef={introRef}/>
        <AboutMe passedRef={aboutMeRef}/>
        <WorkHistory passedRef={workHistoryRef}/>
        <ContactMe passedRef={contactMeRef}/>
    </div>
    );
  }
  
  export default Body; 