import { RefObject } from "react";
import { SocialIcon } from "react-social-icons";
import { ReactTyped } from "react-typed";

function ContactMe({passedRef}: {passedRef: RefObject<HTMLDivElement>}) {
    return (
        <div id={"intro"} ref={passedRef}  className="container section">
            <div className="row">
                <div className="col-sm-12">
                    <ReactTyped className="header" strings={["Contact Me"]} cursorChar="_"/>
                    <p>
                        Shoot me an email or connect with my on my socials!
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div>
                        <SocialIcon className="social-icon" url="https://www.linkedin.com/in/antony-warring/" />
                        <SocialIcon className="social-icon" url="mailto:antony.t.warring@gmail.com" />
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default ContactMe; 