import { RefObject } from "react";
import { ReactTyped } from "react-typed";

function WorkHistory({passedRef}: {passedRef: RefObject<HTMLDivElement>}) {
    return (
        <div id={"intro"} ref={passedRef}  className="container section">
            <div className="row">
                <div className="col-sm-4 image-container">
                    <img src="/bullhorn.jpg" alt="Bullhorn HQ"/>
                </div>  
                <div className="col-sm-8">
                    <ReactTyped className="header" strings={["Work History"]} cursorChar="_"/>
                    <h1>Bullhorn</h1>
                    <h3>Software Engineer II / III</h3>
                    <h4>2020 - Present</h4>
                    <p>
                        Tech Stack:
                        <li>Java/Spring</li>
                        <li>Angular/Typescript</li>
                        <li>SQL</li>
                    </p>
                    <h1>PerkinElmer</h1>
                    <h3>Software Engineer</h3>
                    <h4>2019 - 2020</h4>
                    <p>
                        Tech Stack:
                        <li>VB.NET</li>
                        <li>C#</li>
                        <li>SQL</li>
                    </p>
                    <h1>MRI Software</h1>
                    <h3>Software Engineer I / II</h3>
                    <h4>2018 - 2019</h4>
                    <p>
                        Tech Stack:
                        <li>C#/ASP.NET</li>
                        <li>VB6</li>
                        <li>Angular/Typescript</li>
                        <li>SQL</li>
                    </p>
                </div>
            </div>
        </div>
    );
  }
  
  export default WorkHistory; 